<template>
  <div class="columns is-multiline">
    <div class="column is-2">
      <b-field label="Prefijo"
               :type="errors.prefix ? 'is-danger' : ''"
               :message="errors.prefix">
        <b-input v-model="data.prefix"></b-input>
      </b-field>
    </div>
    <div class="column is-10">
      <b-field label="Nombre(s)"
               :type="errors.name ? 'is-danger' : ''"
               :message="errors.name">
        <b-input
            v-model="data.name"
            ref="patient_name"
            placeholder="Nombre(s)"
            :disabled="!$alfalab.canEditPatient(this)"
            required>
        </b-input>
      </b-field>
    </div>
    <div class="column is-6">
      <b-field label="Apellido Paterno"
               :type="errors.last_name ? 'is-danger' : ''"
               :message="errors.last_name">
        <b-input
            v-model="data.last_name"
            placeholder="Apellido Paterno"
            :disabled="!$alfalab.canEditPatient(this)"
            required>
        </b-input>
      </b-field>
    </div>
    <div class="column is-6">
      <b-field label="Apellido Materno"
               :type="errors.other_name ? 'is-danger' : ''"
               :message="errors.other_name">
        <b-input
            v-model="data.other_name"
            placeholder="Apellido Materno"
            :disabled="!$alfalab.canEditPatient(this)"
            required>
        </b-input>
      </b-field>
    </div>
    <div class="column is-6">
      <select-date label="Fecha de Nacimiento"
                   :disable-initial-date="! modeEdit"
                   v-model="data.birthday"
                   :disabled="!$alfalab.canEditPatient(this)"
                   :error="errors.birthday">
      </select-date>
    </div>
    <div class="column is-6">
      <b-field label="Género"
               :type="errors.gender ? 'is-danger' : ''"
               :message="errors.gender">
        <b-field>
          <div class="block">
            <b-radio v-model="data.gender"
                     name="gender"
                     :disabled="!$alfalab.canEditPatient(this)"
                     native-value="male">
              Masculino
            </b-radio>
            <b-radio v-model="data.gender"
                     :disabled="!$alfalab.canEditPatient(this)"
                     name="gender"
                     native-value="female">
              Femenino
            </b-radio>
          </div>
        </b-field>
      </b-field>
    </div>
    <div class="column is-3">
      <b-field label="No. Empleado"
               :type="errors.employee ? 'is-danger' : ''"
               :message="errors.employee">
        <b-input type="text"
                 v-model="data.employee"
                 placeholder="No. Empleado"></b-input>
      </b-field>
    </div>
    <div class="column is-3">
      <b-field label="Beneficiario"
               :type="errors.beneficiary ? 'is-danger' : ''"
               :message="errors.beneficiary">
        <b-input type="text"
                 v-model="data.beneficiary"
                 placeholder="Beneficiario"></b-input>
      </b-field>
    </div>
    <div class="column is-3">
      <b-field label="Teléfono domicilio"
               :type="errors.phone ? 'is-danger' : ''"
               :message="errors.phone">
        <b-input type="tel"
                 v-model="data.phone"
                 placeholder="55 5555 5555"></b-input>
      </b-field>
    </div>
    <div class="column is-offset-6 is-3">
      <b-field label="Teléfono móvil / Whatsapp"
               :type="errors.cell_phone ? 'is-danger' : ''"
               :message="errors.cell_phone">
        <b-input type="tel"
                 :disabled="data.cell_phone_ignore"
                 v-model="data.cell_phone"
                 maxlength="10"
                 minlength="10"
                 custom-class="lapi-patient-input"
                 placeholder="55 5555 5555"></b-input>
        <b-checkbox v-model="data.cell_phone_ignore" style="margin-left: 20px">
          Ignorar Whatsapp
        </b-checkbox>
      </b-field>
    </div>
    <div class="column is-6">
      <b-field label="Correo"
               :type="errors.email ? 'is-danger' : ''"
               :message="errors.email">
        <b-input type="email"
                 v-model="data.email"
                 custom-class="lapi-patient-input"
                 :disabled="data.email_ignore"
                 placeholder="Correo electrónico"></b-input>
        <b-checkbox
            style="margin-left: 20px"
            v-model="data.email_ignore">
          Ignorar correo electronico
        </b-checkbox>
      </b-field>
    </div>
    <div class="column is-6">
      <b-field label="Confirmar correo"
               :type="errors.email_confirmation ? 'is-danger' : ''"
               :message="errors.email_confirmation">
        <b-input type="email"
                 @copy.native.prevent @paste.native.prevent @click.native.right.prevent
                 v-model="data.email_confirmation"
                 :disabled="data.email_ignore"
                 placeholder="Confirmar correo electrónico"></b-input>
      </b-field>
    </div>
    <div class="column is-6">
      <b-field label="Correo 2"
               :type="errors.email_alt ? 'is-danger' : ''"
               :message="errors.email_alt">
        <b-input type="email"
                 v-model="data.email_alt"
                 placeholder="Correo electrónico"></b-input>
      </b-field>
    </div>
    <div class="column is-6">
      <b-field label="Confirmar Correo 2"
               :type="errors.email_alt_confirmation ? 'is-danger' : ''"
               :message="errors.email_alt_confirmation">
        <b-input type="email"
                 @copy.native.prevent @paste.native.prevent @click.native.right.prevent
                 v-model="data.email_alt_confirmation"
                 placeholder="Confirmar correo electrónico"></b-input>
      </b-field>
    </div>
    <div class="column is-6">
      <b-field label="Correo 3"
               :type="errors.email3 ? 'is-danger' : ''"
               :message="errors.email3">
        <b-input type="email"
                 v-model="data.email3"
                 placeholder="Correo electrónico"></b-input>
      </b-field>
    </div>
    <div class="column is-6">
      <b-field label="Confirmar Correo 3"
               :type="errors.email3_confirmation ? 'is-danger' : ''"
               :message="errors.email3_confirmation">
        <b-input type="email"
                 @copy.native.prevent @paste.native.prevent @click.native.right.prevent
                 v-model="data.email3_confirmation"
                 placeholder="Confirmar correo electrónico"></b-input>
      </b-field>
    </div>
    <div class="column is-6">
      <b-field label="Puesto"
               :type="errors.job_title ? 'is-danger' : ''"
               :message="errors.job_title">
        <b-input type="text"
                 v-model="data.job_title"
                 placeholder="Empleado/Candidato"></b-input>
      </b-field>
    </div>
    <div class="column is-6">
      <form-input label="Área" v-model="data.area" :error="errors.area"></form-input>
    </div>
    <div class="column is-6">
      <form-input label="Centro de costos (C.C.)" v-model="data.cc" :error="errors.cc"></form-input>
    </div>
    <div class="column is-6">
      <form-input label="Departamento" v-model="data.department" :error="errors.depatment"></form-input>
    </div>
    <div class="column is-6">
      <form-input label="Planta" v-model="data.factory" :error="errors.factory"></form-input>
    </div>
    <div class="column is-6">
      <form-input label="Código Postal" v-model="data.postal_code" :error="errors.postal_code"></form-input>
    </div>
    <div class="column is-6">
      <form-input label="Dirección" v-model="data.address" :error="errors.address"></form-input>
    </div>

    <slot></slot>
  </div>
</template>

<script>
import FormPatientTaxData from "./FormPatientTaxData";
import SelectDate from "@/components/partials/SelectDate";
import FormInput from "@/components/partials/FormInput";
export default {
  name: "FormPatient",
  components: {FormInput, SelectDate, FormPatientTaxData},
  props: {
    data: {
      type: Object,
      default() {
        return {}
      }
    },
    errors: {
      type: Object,
      default() {
        return {}
      }
    },
    modeEdit: Boolean
  },
  computed: {
    date() {
      return this.data.birthday ? new Date(this.data.birthday) : null;
    },
    hasTaxData() {
      return this.data.has_tax_data || false;
    }
  },
  mounted() {
    this.$refs.patient_name.focus();
  },
  methods: {
    onSetBirthday(val = null) {
      this.data.birthday = this.$moment(val || new Date()).format();
    }
  }
}
</script>

<style scoped>

</style>
