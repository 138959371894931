<template>
  <form @submit.prevent="onCreate">
    <form-patient-tax-data
        :data="form" :errors="errors"
        :showCFDIUse="false"
        @setTaxData="val => form = val">
    </form-patient-tax-data>
    <b-field>
      <button class="button is-success" type="submit">
        Crear Datos Fiscales
      </button>
    </b-field>
  </form>
</template>

<script>
import FormPatientTaxData from "@/components/patient/FormPatientTaxData";
export default {
  name: "CreatePatientTaxDataForm",
  components: {FormPatientTaxData},
  props: {
    patient: String
  },
  data() {
    return {
      form: {},
      errors: {},
      apiUrl: 'patient-tax-datum',
      hasTaxData: Boolean
    }
  },
  mounted() {
    this.$on('setTaxData', val => {
      console.log(val);
    })
  },
  methods: {
    async onCreate() {
      this.$loader.show();
      this.form.patient = this.patient;
      await this.storeData(this.form)
          .then(data => {
            this.$toast.success(data.message);
            this.$emit('update:hasTaxData', true);
            this.$emit('setTaxData', true);
          },  error => {
            this.$toast.error(error.message);
          });
      this.$loader.hide();
    }
  }
}
</script>

<style scoped>

</style>
