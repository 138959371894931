<template>
  <div class="modal-card" style="width: auto; min-width: 300px;">
    <header class="modal-card-head">
      <p class="modal-card-title">Editar Paciente</p>
      <button
          type="button"
          class="delete"
          @click="$emit('close')"/>
    </header>
    <section class="modal-card-body">
      <b-skeleton :count="4" :height="50" v-if="isLoading"></b-skeleton>
      <b-tabs type="is-boxed" v-else>
        <b-tab-item label="Datos Generales" icon="edit">
          <form-patient :data="data" mode-edit :errors="errors">
            <div class="column is-12">
              <b-field>
                <button class="button is-warning" type="button" @click.stop="onUpdateData">
                  Actualizar Datos
                </button>
              </b-field>
            </div>
          </form-patient>
          <form >

          </form>
        </b-tab-item>
        <b-tab-item label="Datos Fiscales" icon="file">
          <update-patient-tax-data-form :patient="patient" v-if="hasTaxData" :data="taxData"></update-patient-tax-data-form>
          <create-patient-tax-data-form
              @setTaxData="getTaxDataInfo"
              :patient="data.code" v-else>
          </create-patient-tax-data-form>
        </b-tab-item>
      </b-tabs>
    </section>
    <footer class="modal-card-foot">
      <button class="button" type="button" @click="$emit('close')">Cerrar</button>
		<button class="button is-warning" type="button" @click.stop="onUpdateData">
			Actualizar Datos Generales
		</button>
    </footer>
  </div>
</template>

<script>
import FormPatient from "./FormPatient";
import UpdatePatientTaxDataForm from "@/components/patient/forms/UpdatePatientTaxDataForm";
import CreatePatientTaxDataForm from "@/components/patient/forms/CreatePatientTaxDataForm";
export default {
  name: "ModalEditPatient",
  components: {CreatePatientTaxDataForm, UpdatePatientTaxDataForm, FormPatient},
  props: {
    patient: String,
    test: {},
  },
  data() {
    return {
      apiUrl: 'patients',
      data: {},
      taxData: {},
      isLoading: true,
      errors: {},
      taxDataErrors: {},
      activeTab: 'general',
      hasTaxData: false
    }
  },
  computed: {
    idPatient() {
      return this.data.code;
    }
  },
  created() {
    this.prepareComponent();
  },
  methods: {
    async prepareComponent() {
      this.isLoading = true;
      await this.getPatientInfo();
      await this.getTaxDataInfo();
      this.isLoading = false;
    },
    getPatientInfo() {
      this.apiUrl = '/patients'
      return this.getData(this.patient, {
        check_patients_orders_with_sign: true
      })
          .then(data => {
            this.data = data.data;
          }, error => {

          });
    },
    getTaxDataInfo() {
      this.apiUrl = '/patient-tax-datum'
      return this.getData(this.patient)
          .then(data => {
            this.taxData = data.data;
            this.hasTaxData = true;
          }, error => {
            this.hasTaxData = false;
          });
    },
    async onUpdateData() {

      const isEmailValid = await this.$alfalab.onValidateEmail(this, this.data.email);
      const isCompleteInfo = this.checkInfo();

      if(isEmailValid.status && isCompleteInfo){
        this.runUpdatePatient();
      }else if(!isEmailValid.status){
        this.$buefy.dialog.alert({
          title: 'Hay un detalle con el correo proporcionado',
          message: isEmailValid.message,
          confirmText: isEmailValid.allow_continue ? 'Es correcto continuar' : 'Aceptar',
          canCancel: isEmailValid.allow_continue,
          cancelText: 'Cancelar',
          onConfirm: isEmailValid.allow_continue ? this.runUpdatePatient: () => {}
        });
      }
    },
    async runUpdatePatient(){
      this.apiUrl = '/patients';
      this.$loader.show();
      await this.updateData(this.idPatient, this.data)
          .then(data => {
            this.$toast.success(data.message);
            this.data = data.data;
            this.$store.dispatch('order/setPatient', this.data);
          }, error => {
            this.$toast.error(error.message);
          });
      this.$loader.hide();
    },
    checkInfo(){
      let result = true;
      const validEmail = !!this.data.email_ignore || (!!!this.data.email_ignore && !!this.data.email)
      const validWhatsapp = !!this.data.cell_phone_ignore || (!!!this.data.cell_phone_ignore && !!this.data.cell_phone)
      if(!validEmail || !validWhatsapp){
        result = false;
        this.$buefy.dialog.alert({
          title: 'Información requerida',
          message: 'No olvides actualizar el número de teléfono móvil para el WhatsApp en el campo teléfono móvil y el correo electrónico en el campo correo.'
        });
      }
      return result;
    }
  }
}
</script>

<style scoped>

</style>
