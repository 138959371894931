<template>
    <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
            <p class="modal-card-title">
                {{ title }}
            </p>
        </header>
        <section class="modal-card-body">
            <form-patient :data="data"
                          :errors="errors"
                          class="columns is-multiline">
            </form-patient>
        </section>
        <footer class="modal-card-foot">
            <button class="button" type="button" @click="$parent.close()">Cerrar</button>
            <button class="button is-primary" type="button" @click="$emit(actionEvent, data)">
                {{ actionText }}
            </button>
        </footer>
    </div>
</template>

<script>
    import FormPatient from "./FormPatient";
    export default {
        name: "FormModalPatient",
        components: {FormPatient},
        props: {
            data: Object,
            errors: Object|Array,
            title: String,
            actionText: String,
            actionEvent: String
        }
    }
</script>

<style scoped>

</style>